import "../src/shared/wrap_ready.js";
import Cookie from "../src/shared/cookie";

window.Hoepliacademy = {};

window.addEventListener("DOMContentLoaded", () => {
  window.Hoepliacademy.Cookie = new Cookie();
});

// we moved Bootstrap here afer updating to BS5 because
// it wasn't loaded in the right order in application.js
import * as bootstrap from '../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js';

window.bootstrap = bootstrap;

import "../../whitelabels/hoepliacademy/assets/javascripts/application.js";
